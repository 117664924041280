import Registration from '../../Components/Auth/Registration/Registration'

function RegistrationPage() {
    return (
        <>
            <Registration />
        </>
    )
}

export default RegistrationPage