import TicketScanner from "../../Components/TicketScanner/TicketScanner"

const TicketScanerPage = () => {
    return (
        <>
        <TicketScanner />
        </>
    )
}

export default TicketScanerPage