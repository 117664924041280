import UsersList from "./UsersList/UsersList"

const UsersPage = () => {
    return (
        <>
        <UsersList />
        </>
    )
}

export default UsersPage