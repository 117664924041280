import Login from '../../Components/Auth/Login/Login'

function LoginPage() {
    return (
        <>
            <Login />
        </>
    )
}

export default LoginPage